@charset "utf-8";
/*------------------------------------------------------------
	 main
------------------------------------------------------------*/
#main a p:after {
  width: 100%;
  height: 100%;
  content: '';
  position: absolute;
  left: 0;
  top: 0;
  z-index: 10;
}

/* svg * {
  stroke: currentColor;
  fill-opacity: 0;
  transition: fill-opacity 1s;
}
.finished * {
  fill-opacity: 1;
} */
/*
path {
  transition: 1s fill;
} */

#main .bigPhoto {
  width: 73.2%;
  position: relative;
  overflow: hidden;
}

#main .bigPhoto .title {
  position: absolute;
  bottom: -20%;
  font-size: 60px;
  left: 10%;
  z-index: 100;
}

#main .sec02 .video_button {
  position: absolute;
  bottom: 50%;
  left: 50%;
  transform: translateY(50%) translateX(-50%);
  z-index: 110;
}

#main .sec03 .video_button {
  position: absolute;
  bottom: 5%;
  right: 2%;
  z-index: 110;
}

#main .bigPhoto .video_button img {
  opacity: 0.5;
}

#main .bigPhotoR {
  float: right;
}

#main .bigPhoto img {
  width: 100%;
  transition: opacity 0.8s ease;
  -moz-transition: opacity 0.8s ease;
  -webkit-transition: opacity 0.8s ease;
}

#main .bigPhoto:hover img {
  opacity: 0.7;
}

#main .indexTxt {
  margin-top: 20px;
  display: block;
  font-size: 15px;
  font-weight: lighter;
  /* font-weight: bold; */
  /* letter-spacing: 2px; */
}

#main .indexTxt span {
  margin-right: 19px;
  font-size: 15px;
  font-weight: normal;
  letter-spacing: 0.9px;
}

#main .indexTxt em {
  font-style: normal;
  font-weight: normal;
  font-size: 40px;
}

#main .indexTxt01 {
  margin-right: 30px;
  position: absolute;
  right: 100%;
  bottom: 5px;
  text-align: right;
  vertical-align: bottom;
  width: 20px;
  height: 200px;
  writing-mode: tb-rl;
  -webkit-writing-mode: vertical-rl;
  direction: ltr;
  letter-spacing: 1px;
}

#main .indexTxt01 span {
  margin: 0 0 19px;
}

#main .sec01 {
  height: 100vh;
  text-align: center;
  background-color: #fff;
  position: relative;
}

#main .sec01 img {
  width: 30%;
}
#main .sec01:before {
  /* background: url(../images/flower.png) center bottom no-repeat; */
  left: 50%;
  position: absolute;
  top: calc(100% - 72px);
  transform: translateX(-36px);
  width: 72px;
  height: 0px;
  content: '';
  transition: height 0.8s ease 1.8s;
}
#main .leave:before {
  /* animation: tinRightOut 0.8s ease-in; */
}

.loaded #main .sec01:before {
  height: 72px;
}

#main .sec01 h2 {
  margin-top: -35px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  -o-transform: translate(-50%, -50%);
  -webkit-transform: translate(-50%, -50%);
  opacity: 0;
  visibility: hidden;
}

#main .sec01 h2 svg {
  transform: scale(0.5);
  transform-origin: center bottom;
}

#svg-animation .st0 {
  fill: none;
  stroke: #fff;
  stroke-width: 20;
  stroke-linecap: round;
  stroke-linejoin: round;
  stroke-miterlimit: 10;
  stroke-dasharray: 1500;
  stroke-dashoffset: 1500;
}

#svg-animation {
  max-width: 100%;
  height: auto;
}

#main .sec01 h2 span {
  margin-top: 28px;
  display: block;
  font-weight: normal;
  font-size: 16px;
  font-family: 'Crimson Text', serif;
  letter-spacing: 1.5px;
}

#main .sec02 {
  height: 100vh;
  overflow: hidden;
  /* margin-bottom: 200px; */
  text-align: center;
  position: relative;
}

#main .sec02 .fixImg {
  /* position: absolute;
  z-index: 0;
  left: 0; */
  top: 0;
  width: 100%;
  height: 100vh;
  /* background: url(../images/sec02_bg.jpg) no-repeat center center; */
  background-size: cover;
  transition: opacity 1s ease;
}

#main .sec02 .sound {
  background: url('../images/sound.png') no-repeat;
  position: absolute;
  right: 5%;
  top: 10%;
  width: 50px;
  height: 30px;
  z-index: 999;
  cursor: pointer;
}
#main .sec02 .sounding {
  background: url('../images/sounding.png') no-repeat;
}
#main .sec03 {
  height: 100vh;
  overflow: hidden;
  /* margin-bottom: 200px; */
  position: relative;
}
#main .sec03 .bigPhoto {
  width: 50%;
  overflow: inherit;
}
#main .sec03 .textBox {
  margin-top: 8px;
  position: absolute;
  width: 28.3%;
  transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  -o-transform: translateY(-50%);
  -webkit-transform: translateY(-50%);
}

#main .sec08 .textBox {
  left: 9.5%;
}
#main .sec03 .textBox {
  right: 5.5%;
  width: 37%;
  bottom: 5%;
}

#main .sec03 .textBox p {
  margin-bottom: 10px;
}

#main .textBox embed {
  width: 100%;
}

#main .sec04 {
  height: 100vh;
  overflow: hidden;
  /* margin-bottom: 200px; */
  position: relative;
}

#main .sec04 .photo {
  width: 24%;
  float: left;
  /* margin-top: 3%; */
  margin-right: 14%;
  overflow: hidden;
}

#main .sec04 .photo:nth-child(2) {
  margin: 0px;
}

#main .sec04 .photo:nth-child(3) {
  float: right;
  margin-right: 0px;
}

#main .sec04 .textBox {
  text-align: center;
}
#main .sec04 .bigPhoto {
  width: 100%;
}

#main .sec05 {
  height: 100vh;
  overflow: hidden;
  /* margin-bottom: 200px; */
  position: relative;
}
#main .sec05 .bigPhoto,
#main .sec06 .bigPhoto,
#main .sec07 .bigPhoto {
  width: 60%;
}
#main .sec05 .textBox {
  position: absolute;
  left: 15%;
  bottom: 25%;
  width: 35.85%;
}

#main .sec07 .textBox p.bigTitle,
#main .sec06 .textBox p.bigTitle,
#main .sec05 .textBox p.bigTitle,
#main .sec04 .textBox p.bigTitle {
  font-size: 28px;
  font-weight: 400;
  margin-bottom: 20px;
  color: #333;
}
#main .sec05 .textBox p,
#main .sec04 .textBox p {
  margin-bottom: 10px;
}

#main .sec05 .textBox embed {
  width: 100%;
}

#main .sec05 .txt {
  bottom: 3px;
}

#main .sec06 {
  height: 100vh;
  overflow: hidden;
  /* margin-bottom: 201px; */
  position: relative;
}

#main .sec06 .textBox {
  margin-top: 7px;
  position: absolute;
  right: 2%;
  bottom: 25%;
  width: 35.85%;
}

#main .sec06 .textBox p,
#main .sec07 .textBox p {
  margin-bottom: 10px;
}

#main .sec06 a .bigPhoto img {
  width: 100%;
  transition: all 1.6s ease 0s;
  -moz-transition: all 1.6s ease 0s;
  -webkit-transition: all 1.6s ease 0s;
}

#main .sec07 {
  height: 100vh;
  overflow: hidden;
  /* margin-bottom: 200px; */
  position: relative;
}

#main .sec07 .textBox {
  position: absolute;
  left: 18%;
  bottom: 25%;
  width: 38%;
}

#main .sec07 .textBox embed {
  padding-bottom: 77px;
}

#main .sec08 {
  margin-bottom: 200px;
  position: relative;
}

#main .sec08 .textBox {
  margin-top: -18px;
  left: auto;
  right: 4.2%;
  width: 32%;
}

#main .sec09 {
  position: relative;
  height: 100vh;
}

#main .sec09 .fixImg {
  position: absolute;
  z-index: 1;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  transition: opacity 0s ease;
  overflow: hidden;
}

#main .sec09 a {
  display: block;
  height: 100%;
  width: 100%;
  position: relative;
  z-index: 1;
  opacity: 0;
  transition: opacity 0s ease;
}

#main .sec09 p {
  position: absolute;
  top: 50%;
  left: 0;
  right: 0;
  z-index: 1;
  text-align: center;
  transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  -o-transform: translateY(-50%);
  -webkit-transform: translateY(-50%);
  transition: opacity 0.6s ease;
}

#main .sec09 p .indexTxt {
  margin-top: -72px;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  text-align: center;
  color: #fff;
}

#main .sec09 p embed {
  width: 29%;
}

#main {
  position: relative;
}

#main:before {
  /* background: #000; */
  left: 50%;
  position: absolute;
  bottom: -50px;
  width: 1px;
  height: 50px;
  content: '';
}

#main .sec09.show .fixImg {
  opacity: 1;
  transition: opacity 0.8s ease;
}

#main .sec09.show a {
  opacity: 1;
  transition: opacity 0.8s ease 0.8s;
}

.secBox {
  width: 100%;
  height: 100%;
  transition: opacity 0.6s ease;
}

.secBox:hover {
  opacity: 0.8;
}

#gFooter .fBg {
  display: block;
}

#gHeader h1,
#gHeader .menu,
#main .sec01 h2 span {
  opacity: 0;
  transition: opacity ease 0.8s;
}

#gHeader h1,
#gHeader .menu {
  transition: top 0.6s ease, opacity ease 0.8s;
}

#main .sec_title {
  width: 35%;
  margin: 0 auto 30px;
}
#main .sec_title img {
  width: 100%;
}

#main .threePhotos {
  margin: 0 8%;
}
#main .sec01 h2 span {
  transition-delay: 2.3s;
}

.loaded #gHeader h1,
.loaded #gHeader .menu,
.loaded #main .sec01 h2 span {
  opacity: 1;
}

#gFooter .fNavi li a {
  border-bottom: none;
}

.target .bigPhoto {
  opacity: 0;
  transition: opacity 0.8s ease-out;
}
.target .bigPhoto .title {
  opacity: 0;
  transition: opacity 0.8s ease-out;
}

.target .sec_title {
  opacity: 0;
  transition: opacity 0.8s ease-out;
}

.target .textBox p {
  opacity: 0;
  transform: translateY(50px);
  transition: opacity 0.8s ease-out 0s, transform 0.6s ease-out 0s;
}

.target.visible .bigPhoto .title {
  opacity: 0.5;
}
.target.visible .sec_title {
  opacity: 1;
}

.target.visible .bigPhoto {
  opacity: 1;
}

.target.visible .textBox p {
  opacity: 1;
  transform: translateY(0);
  transition: opacity 0.8s ease-out 0.5s, transform 0.6s ease-out 0.5s;
}

.target.visible .textBox p > span {
  display: block;
}

@media all and (min-width: 0) and (max-width: 767px) {
  #main a {
    display: block;
  }

  #main .sec01 img {
    width: 70%;
  }
  #gFooter .about .address p {
    font-size: 13px;
    line-height: 28px;
  }
  #gFooter .about .address p span {
    display: block;
  }
  #main .bigPhoto {
    width: 88%;
    float: none !important;
  }

  #main .bigPhotoR {
    text-align: right;
    width: 100%;
  }

  #main .bigPhotoR img.sp {
    display: inline-block !important;
    width: 100%;
  }
  #main .sec05 .bigPhoto,
  #main .sec06 .bigPhoto,
  #main .sec07 .bigPhoto {
    width: 100%;
  }

  #main .sec06 a:hover .bigPhoto img,
  #main .bigPhoto a:hover img {
    transform: none;
  }

  #main .bigPhoto:hover img {
    transform: none;
  }

  #main .indexTxt {
    margin-top: 27px;
    font-size: 10px;
    letter-spacing: 1px;
  }

  #main .indexTxt span {
    margin-right: 10px;
    font-size: 13px;
    letter-spacing: 0px;
  }

  #main .indexTxt p {
    font-size: 15px;
  }

  #main .sec_title {
    width: 70%;
    margin: 80px auto 30px;
  }
  #main .threePhotos {
    margin: 0;
  }
  #main .indexTxt01 {
    margin: 25px 0 0;
    position: static;
    text-align: left;
    width: auto;
    height: auto;
    writing-mode: lr-tb;
    -webkit-writing-mode: horizontal-tb;
    letter-spacing: 1px;
  }

  #main .sec01 {
    height: 100vh;
  }

  #main .sec01:before {
    top: calc(100% - 72px);
  }

  .loaded #main .sec01:before {
    height: 72px;
  }

  #main .sec01 h2 {
    margin-top: 0;
    width: 219px;
    opacity: 1;
  }

  #main .sec01 h2 svg {
    transform: none;
  }

  #main .sec01 h2 span {
    margin-top: 24px;
    font-size: 14px;
    letter-spacing: 1.3px;
  }

  #main .sec02 {
    margin-bottom: 35px;
    position: relative;
  }

  #main .sec02 .fixImg {
    /* background-image: url(../images/sec02_bg.jpg); */
    background: #ccc;
  }

  #main .sec02 .topBox h2 {
    margin-bottom: 65px;
  }

  #main .sec02 .topBox h2 svg {
    margin: 0 auto;
    width: 59.5%;
  }

  #main .sec02 .topBox h2 .indexTxt {
    margin: 0 0 42px 0;
    letter-spacing: 2px;
  }

  #main .sec02 .topBox h2 .indexTxt span {
    margin: 0 16px 0 0;
    letter-spacing: 1.2px;
  }

  #main .sec02 .topBox p {
    margin: 0 6% 45px;
    max-width: inherit;
    font-size: 12px;
    line-height: 3.75;
    letter-spacing: 1.5px;
  }

  #main .sec02 .topBox .textImg {
    margin: 0 6% 0 5.5%;
    max-width: inherit;
  }

  #main .sec02 .topBox .textImg img {
    width: 99%;
  }

  #main .sec03 {
    margin-bottom: 68px;
  }

  #main .sec03 .bigPhoto {
    margin-top: 18%;
    width: 100%;
    margin-bottom: 23px;
  }
  #main .sec03 .bigPhoto .title {
    display: none;
  }
  #main .sec08 .textBox,
  #main .sec03 .textBox {
    margin: 0 0 0 12%;
    position: static;
    width: auto;
    transform: translate(0, 0);
    -moz-transform: translate(0, 0);
    -webkit-transform: translate(0, 0);
    -ms-transform: translate(0, 0);
    -o-transform: translate(0, 0);
  }

  #main .sec03 .textBox {
    margin: 0 10%;
  }

  #main .sec03 .textBox embed {
    width: 77.87%;
  }

  #main .sec04 {
    margin-bottom: 68px;
  }
  #main .sec04 .photo {
    width: 100%;
    float: none;
  }
  #main .sec04 .textBox p.bigTitle,
  #main .sec05 .textBox p.bigTitle,
  #main .sec06 .textBox p.bigTitle,
  #main .sec07 .textBox p.bigTitle {
    font-size: 20px;
    margin-bottom: 30px;
  }

  #main .sec04 .textBox p.bigTitle {
    margin-bottom: 10px;
  }
  #main .sec04 .bigPhoto {
    margin-bottom: 22px;
  }

  #main .sec04 .textBox {
    position: static;
    width: auto;
  }

  #main .sec04 .textBox embed {
    width: 90.5%;
  }

  #main .sec05 {
    margin-bottom: 70px;
  }

  #main .sec05 .bigPhoto {
    margin-bottom: 22px;
  }

  #main .sec05 .textBox {
    margin: 0 0 0 12%;
    position: static;
    width: auto;
  }

  #main .sec05 .textBox embed {
    width: 96.5%;
  }

  #main .sec06 {
    margin-bottom: 68px;
    width: 100%;
  }

  #main .sec06 .bigPhoto {
    margin-bottom: 23px;
  }

  #main .sec06 .textBox {
    margin: 0 0 0 12%;
    position: static;
    width: auto;
    transform: translate(0, 0);
    -moz-transform: translate(0, 0);
    -webkit-transform: translate(0, 0);
    -ms-transform: translate(0, 0);
    -o-transform: translate(0, 0);
    top: auto;
    left: auto;
  }

  #main .sec06 .textBox embed {
    width: 80.5%;
  }

  #main .sec07 {
    margin-bottom: 68px;
  }

  #main .sec07 .textBox {
    margin: 0 0 0 12%;
    position: static;
    width: auto;
  }

  #main .sec07 .bigPhoto {
    margin-bottom: 23px;
  }

  #main .sec07 .textBox embed {
    padding-bottom: 0;
    width: 87.6%;
  }

  #main .sec08 {
    margin-bottom: 95px;
  }

  #main .sec08 .bigPhoto {
    margin-bottom: 23px;
  }

  #main .sec08 .textBox {
    margin-top: 0;
    width: auto;
  }

  #main .sec08 .textBox embed {
    width: 87.5%;
  }

  #main .sec09 {
    height: 100vh;
  }

  #main .sec09.show:hover .fixImg .sub {
    transform: none;
  }

  #main .sec09 p {
    margin-top: -20px;
  }

  #main .sec09 p > span {
    display: block;
  }

  #main .sec09 p .indexTxt {
    margin-top: -64px;
    letter-spacing: 2px;
  }

  #main .sec09 p .indexTxt span {
    margin-right: 15px;
    font-size: 15px;
  }

  #main .sec09 p embed {
    margin: 0 auto;
    width: 58.5%;
  }
  #main .sec04 .bigPhoto {
    display: none;
  }
  #main .sec04 .indexTxt {
    margin-top: 0px;
  }
  #main .sec09:before {
    display: none;
  }

  #main .sec09 a:hover p,
  #main .sec09 a:hover p embed {
    opacity: 1;
  }

  .secBox:hover {
    opacity: 1;
  }

  .iphone5 #main .sec02 .topBox p {
    letter-spacing: -0.3px;
  }
  #gFooter .qrcode {
    width: 100%;
  }
  .pageTop {
    display: none;
  }
  #gFooter .qrcode img {
    width: 50%;
  }
  #gFooter .about {
    width: 100%;
  }
  #gFooter .about .contacts {
    height: 45px;
    line-height: 45px;
    width: 100%;
    margin-bottom: 30px;
  }
  #gFooter .about .contacts .contact span {
    display: block;
  }
  #gFooter .copyright span {
    display: block;
  }
}

@media screen and (min-width: 768px) and (max-width: 1280px) {
  #main .sec03 .textBox {
    bottom: 0;
  }
  #main .indexTxt {
    margin-top: 15px;
    font-size: 14px;
  }

  #main .sec07 .textBox p.bigTitle,
  #main .sec06 .textBox p.bigTitle,
  #main .sec05 .textBox p.bigTitle,
  #main .sec04 .textBox p.bigTitle {
    margin-bottom: 15px;
  }

  #main .sec07 .textBox p,
  #main .sec06 .textBox p,
  #main .sec05 .textBox p,
  #main .sec04 .textBox p {
    margin-bottom: 8px;
  }

  .menuBox .subMenu .menuUl02 {
    margin-top: 0px;
  }
  #main .sec04 .photo {
    width: 26%;
    float: left;
    /* margin-top: 3%; */
    margin-right: 11%;
    overflow: hidden;
  }

  #main .sec04 .photo {
    text-align: center;
  }
  #main .sec04 .photo img {
    width: 80%;
    text-align: center;
  }
  #main .sec_title {
    width: 26%;
  }
  #main .sec05 .textBox {
    left: 17%;
  }
  #main .sec05 .textBox p {
    margin-bottom: 15px;
  }
  #main .sec06 .textBox {
    right: 2%;
  }
}

@media screen and (min-width: 1681px) and (max-width: 1980px) {
  #main .sec03 .textBox {
    bottom: 15%;
  }
  #main .textBox p {
    font-size: 17px;
  }

  #main .sec04 .photo {
    width: 22%;
    float: left;
    /* margin-top: 3%; */
    margin-right: 17%;
    overflow: hidden;
  }

  #main .sec_title {
    margin-top: 50px;
  }
  #main .sec05 .textBox {
    left: 17%;
  }
  #main .sec05 .textBox p {
    margin-bottom: 15px;
  }
  #main .sec06 .textBox {
    right: 2%;
  }
  #gFooter .about {
    width: 75%;
  }
  #gFooter .about .address p {
    font-size: 15px;
  }
  #main .copyright {
    font-size: 16px;
  }
}

@media screen and (min-width: 1981px) and (max-width: 2980px) {
  #main .sec03 .textBox {
    bottom: 15%;
  }
  #main .textBox p {
    font-size: 17px;
  }

  #main .sec04 .photo {
    width: 26%;
    float: left;
    /* margin-top: 3%; */
    margin-right: 11%;
    overflow: hidden;
  }

  #main .sec_title {
    margin-top: 50px;
  }
  #main .sec05 .textBox {
    left: 17%;
  }
  #main .sec05 .textBox p {
    margin-bottom: 15px;
  }
  #main .sec06 .textBox {
    right: 2%;
  }
  #gFooter .about {
    width: 75%;
  }
  #gFooter .about .address p {
    font-size: 15px;
  }
  #main .copyright {
    font-size: 16px;
  }
}
